/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav >
            <ul>
              <li>
                Autoilija Jaakko Liikamaa
              </li>
              <li>
                Y-tunnus: 1318247-2
              </li>
              <li>
                Kalaholmantie 6, 28330 Pori
              </li>
            </ul>
          </nav>    
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
